import { TronContexts } from "contexts/TronContexts";
import { useContext, useEffect, useState } from "react";

const usePrice = () => {
  const { tronWeb } = useContext(TronContexts)
  const [price, setPrice] = useState({
    energyPrice: 420,
    bandwidthPrice: 1000,
  });
  const getPrice = async () => {
    try {
      let newchainParam = await tronWeb.trx.getChainParameters();
      if (newchainParam) {
        let getEnergyFee = newchainParam.find((item: any) => {
          return item.key == "getEnergyFee";
        });
        let getBWFee = newchainParam.find((item: any) => {
          return item.key == "getTransactionFee";
        });
        setPrice({
          bandwidthPrice: getBWFee.value || 1000,
          energyPrice: getEnergyFee.value || 420,
        });
      }
    } catch (error) {
      console.log("error get price", error);
    }
  };
  useEffect(() => {
    if (tronWeb) {
      getPrice();
    }
  }, [tronWeb]);
  return price;
};

export default usePrice;
